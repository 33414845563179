<template>
	<div style="padding-top: 6px;">
		<div style="display: flex;">
			<!-- 重写关闭按钮 -->
			<span class="close" @click="closeDrawer">
				<i class="el-icon-close" style="color: #FFFFFF;font-size: 26px;line-height: 30px;"></i>
			</span>
			<!-- 左侧卡片 -->
			<div style="width: 60%;height: 98vh; overflow-y: auto; padding: 0 8px 0 4px;">
				<div style="display: block;">
					<!-- 沟通进度 -->
					<div>
						<el-card class="box-card-son">
							<div slot="header" class="clearfix">
								<span>沟通进度</span>
								<el-checkbox style="float: right; padding: 3px 0; color: red; margin-right: 0px;"
									v-model="nFailure" :disabled='nFailure || arrCustomer.state == 6' @change="showFaileBox = true">沟通失败
								</el-checkbox>
								<el-checkbox
									style="float: right; padding: 3px 20px; color: #409EFF;line-height: 1;font-weight: 500; font-size: 14px; white-space: nowrap;"
									v-model="nSuccess" :disabled='nFailure || nSuccess' @change="showConfirmBox = true">
									确定意向</el-checkbox>
							</div>
							<el-steps :active="arrCustomer.state" finish-status="success" process-status="finish"
								align-center>
								<el-step title="转换线索" :status="getStepStatus(1)"></el-step>
								<el-step title="分配客户" :status="getStepStatus(2)"></el-step>
								<el-step title="开始沟通" :status="getStepStatus(3)"></el-step>
								<el-step title="明确意向" :status="getStepStatus(4)"></el-step>
								<el-step title="上传作品" :status="getStepStatus(5)"></el-step>
								<el-step title="转化成功" :status="getStepStatus(6)"></el-step>
							</el-steps>
						</el-card>
					</div>
					<!-- 作品管理 -->
					<div style="margin-top: 20px;">
						<el-card>
							<div slot="header" class="clearfix">
								<span>客户信息</span>
								<el-button style="float: right; padding: 3px 0" type="text" @click=""
									:disabled="nFailure || !nSuccess" @click="showWorkBox = true">管理客户作品</el-button>
							</div>
							<div
								style="background-color: #fafafa;display: flex;justify-content: space-around; text-align: center;padding: 20px; ">
								<div style="width: 33%;">
									<div class="div-span">客户来源</div>
									<div class="card-info">{{arrCustomer.platform_name}}</div>
								</div>
								<div style="width: 33%;">
									<div class="div-span">所属用户</div>
									<div class="card-info">{{arrCustomer.user_name}}</div>
								</div>
								<div style="width: 33%;">
									<div class="div-span">参赛作品</div>
									<div class="card-info">提交{{worksList.length}}/审核{{worksAudit}}</div>
								</div>
								<div style="width: 33%;">
									<div class="div-span">所属活动</div>
									<div class="card-info">{{arrCustomer.activity_name}}</div>
								</div>
							</div>
						</el-card>
					</div>
					<!-- 客户资料 -->
					<div style="padding: 20px 0;">
						<el-card class="box-card-son">
							<div slot="header" class="clearfix">
								<span>客户资料</span>
								<el-button style="float: right; padding: 3px 20px" type="text" @click="dialogVisible=true"
									:disabled="nFailure">修改客户信息</el-button>
							</div>
							<el-descriptions title="" style="padding: 6px 0;" :column="2"
								:contentStyle="{color:'#000000','font-size':'16px'}">
								<el-descriptions-item label="客户姓名">{{arrCustomer.name}}</el-descriptions-item>
								<el-descriptions-item label="联系电话">{{arrCustomer.phone}}</el-descriptions-item>
								<el-descriptions-item label="使用微信">{{arrCustomer.add_wechat}}</el-descriptions-item>
								<el-descriptions-item label="拨打电话">{{arrCustomer.call_phone == 0 ? '未拨打' : '已拨打'}}</el-descriptions-item>
								<el-descriptions-item label="创建时间">{{arrCustomer.create_time}}</el-descriptions-item>
								<el-descriptions-item label="更新时间">{{arrCustomer.update_time}}</el-descriptions-item>
								<el-descriptions-item label="好友昵称">{{arrCustomer.friend_name}}</el-descriptions-item>
							</el-descriptions>
						</el-card>
					</div>
					<!-- 沟通记录 -->
					<div>
						<el-card class="box-card-son">
							<div slot="header" class="clearfix">
								<span>沟通记录</span>
							</div>
							<el-input type="textarea" resize="none" v-model="mark" rows="4" placeholder="请输入沟通记录"
								:disabled="nFailure"></el-input>
							<div style="padding: 20px;text-align: center;">
								<el-button type="warning" @click="mark = ''">清 空</el-button>
								<el-button type="primary" @click="addRecord()">提 交</el-button>
							</div>
						</el-card>
					</div>
				</div>
			</div>
			<!-- 右侧卡片 -->
			<div style="margin-left: 20px;width: 40%;">
				<el-card class="box-card-log">
					<span>沟通记录</span>
					<el-divider></el-divider>
					<!-- 变更日志 -->
					<div>
						<div style="margin:20px 20px;">
							排序：
							<el-radio-group v-model="reverse">
								<el-radio :label="true">倒序</el-radio>
								<el-radio :label="false">正序</el-radio>
							</el-radio-group>
						</div>
						<div style="height: 77vh; overflow-y: auto; padding: 20px 0;">
							<el-timeline :reverse="reverse">
								<el-timeline-item v-for="(activity, index) in activities" :key="index"
									:timestamp="activity.create_time">
									<div>
										{{activity.remark}}
									</div>
									<div>
										{{activity.opera_name}}
									</div>
								</el-timeline-item>
							</el-timeline>
						</div>

					</div>
				</el-card>
			</div>
		</div>

		<!-- 沟通失败弹窗 -->
		<el-dialog title="沟通失败" :visible.sync="showFaileBox" width="25%" :show-close="false"
			:close-on-click-modal="false" :close-on-press-escape="false" append-to-body>
			<el-input type="textarea" :rows="3" placeholder="请输入备注信息" v-model="failMark">
			</el-input>
			<span slot="footer" class="dialog-footer">
				<el-button @click="closeFail">取 消</el-button>
				<el-button type="primary" @click="contantFailure">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 确认意向 -->
		<el-dialog title="确认客户意向" :visible.sync="showConfirmBox" width="25%" :show-close="false"
			:close-on-click-modal="false" :close-on-press-escape="false" append-to-body>
			<el-input type="textarea" :rows="3" placeholder="请输入备注信息" v-model="confirmMark">
			</el-input>
			<span slot="footer" class="dialog-footer">
				<el-button @click="showConfirmBox = false">取 消</el-button>
				<el-button type="primary" @click="confirmSuccess">确 定</el-button>
			</span>
		</el-dialog>

		<!-- 管理作品 -->
		<el-dialog title="管理客户作品" :visible.sync="showWorkBox" width="55%"
			:close-on-click-modal="false" :close-on-press-escape="false" append-to-body center>
			<el-table :data="worksList" style="width: 100%">
				<el-table-column prop="type" label="作品类型" align="center"></el-table-column>
				<el-table-column prop="content" label="作品内容" align="center" show-overflow-tooltip>
					<template slot-scope="scope">
						<div v-if="scope.row.type == '诗歌'">
							{{scope.row.content}}
						</div>
						<div v-if="scope.row.type == '书画'">
							<el-image style="width: 100px; height: 100px":src="scope.row.work_image_path" fit="fill" :preview-src-list="[scope.row.work_image_path]"></el-image>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="step" label="作品状态" align="center">
					<template slot-scope="scope">
						<div v-if="scope.row.step == 0">
							<span class="tag" style="background-color:#00BFFF"></span>待审核
						</div>
						<div v-if="scope.row.step == 1">
							<span class="tag" style="background-color:#00CED1"></span>审核中
						</div>
						<div v-if="scope.row.step == 2">
							<span class="tag" style="background-color:#32CD32"></span>审核通过
						</div>
					</template>
				</el-table-column>
				<el-table-column  label="操作" width="160" align="center">
					<template slot-scope="scope">
						<el-button type="text"  @click="editWork(scope.row)">查看</el-button>
						<el-button type="text" @click="deleteWork(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			  </el-table>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="showWorkForm = true">添加作品</el-button>
			</span>
		</el-dialog>
		<!-- 添加作品 -->
		<el-dialog title="添加客户作品" :visible.sync="showWorkForm" width="37%"
			:close-on-click-modal="false" :close-on-press-escape="false" append-to-body center v-el-drag-dialog>
			<el-form :model="arrWorkForm" ref="arrWorkForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="作品类型" prop="type">
					<el-radio-group v-model="arrWorkForm.type">
						<el-radio label="1" >诗歌</el-radio>
						<el-radio label="2" >书画</el-radio>
					</el-radio-group>
				</el-form-item>
				<div v-if="arrWorkForm.type == 1">
					<el-form-item label="作品内容" prop="content">
						<el-input type="textarea" :rows="10" v-model="arrWorkForm.content"></el-input>
					</el-form-item>
					<el-form-item label="备注信息" prop="remark">
						<el-input type="textarea" :rows="4" v-model="arrWorkForm.remark"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="addWork()">提交作品</el-button>
						<el-button >重置</el-button>
					</el-form-item>
				</div>
				
				<div v-if="arrWorkForm.type == 2">
					<el-form-item label="图片上传" prop="content">
						<template style="margin-left: 5px">
						<el-upload
						  drag
						  multiple
						  :action="ADMIN_API_URL + sUrl + '/uploadFile'"
						  list-type="picture-card"
						  :on-preview="handlePictureCardPreview"
						  :on-remove="handleRemove"
						  :headers="uploadHeader"
						  :on-success="(response, file, fileList) => { handleAvatarSuccess(response, file, fileList, item);}"
						  >
						  <i class="el-icon-plus"></i>
						</el-upload>
						<el-dialog :visible.sync="showBigWork" :modal="false">
						  <img width="100%" :src="dialogImageUrl" alt="">
						</el-dialog>
						
					</template>
					<div style="margin-top: 20px;">
						<el-form-item>
							<el-button type="primary" @click="addFileWork()">提交作品</el-button>
							<el-button >重置</el-button>
						</el-form-item>
					</div>
					
					</el-form-item>
					
				</div>
				
			</el-form>
		</el-dialog>
		<!-- 变更客户信息弹窗 -->
		<el-dialog title="变更客户信息" :visible.sync="dialogVisible" width="45%" append-to-body :close-on-click-modal="false">
			<el-form :model="arrCustomer" label-width="80px" label-position="right">
				<el-row :gutter="20">
					<el-col :span="20" :push="2">
						<el-form-item label="微信好友" prop="wechat_friend">
							<el-row :gutter="20">
								<el-col :span="20">
									<el-select v-model="arrCustomer.wechat_friend" :filterable="true" placeholder="请选择好友" style="width:100%">
										<el-option :label="item.name" :value="item.id"
											v-for="(item,key) in arrWechatFrinend" :key="key">
											{{ item.name = item.nickname+'【'+ item.remark +'】'}}
										</el-option>
									</el-select>
								</el-col>
							</el-row>
						</el-form-item>
					</el-col>
					<el-col :span="11" :push="2">
						<el-form-item label="客户姓名" prop="nickname">
							<el-row :gutter="20">
								<el-col :span="20">
									<el-input v-model="arrCustomer.name" placeholder="">
									</el-input>
								</el-col>
							</el-row>
						</el-form-item>
					</el-col>
					<el-col :span="11" :push="2">
						<el-form-item label="联系电话" prop="nickname">
							<el-row :gutter="20">
								<el-col :span="20">
									<el-input v-model="arrCustomer.phone" placeholder="">
									</el-input>
								</el-col>
							</el-row>
						</el-form-item>
					</el-col>
				</el-row>

				<el-row :gutter="20">
					<el-col :span="11" :push="2">
						<el-form-item label="使用微信" prop="">
							<el-row :gutter="20">
								<el-col :span="20">
									<el-input v-model="arrCustomer.add_wechat" placeholder="">
									</el-input>
								</el-col>
							</el-row>
						</el-form-item>
					</el-col>
					<el-col :span="11" :push="2">
						<el-form-item label="拨打电话" prop="">
							<el-row :gutter="20">
								<el-col :span="20">
									<el-radio-group v-model="arrCustomer.call_phone">
										<el-radio :label="0">未拨打</el-radio>
										<el-radio :label="1">已拨打</el-radio>
									</el-radio-group>
								</el-col>
							</el-row>
						</el-form-item>
					</el-col>
				</el-row>

				<el-row :gutter="20">
					<el-col :span="24" :push="2">
						<el-form-item label="变更备注" prop="nickname">
							<el-row :gutter="20">
								<el-col :span="20">
									<el-input type="textarea" v-model="arrCustomer.remark" placeholder="">
									</el-input>
								</el-col>
							</el-row>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>

			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="editUser">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import oJs from "@/js/config.js";
	import oIndex from "@/js/customer/index.js";
	import Uploads from "@/components/uploadv2.vue";
	import config from "../../../../config.js";
	let ADMIN_API_URL = config.host + '/admin';
	
	export default {
		components: {
			Uploads
		},
		props: {
			itemData: {
				type: Object,
				default: {},
			},
		},
		data() {
			return {
				nSteps: 0, // 步骤条
				mark: '', // 备注信息
				nFailure: false, // 沟通失败标识
				nSuccess: false, //确认意向标识
				reverse: true, // 时间线排序方式
				activities: [], // 时间线的数据
				loading: false, //页面加载
				showFaileBox: false, // 展示沟通失败弹窗
				failMark: '', // 沟通失败的备注信息
				showConfirmBox: false, // 显示确认意向弹窗
				confirmMark: '', // 确认意向的备注信息
				arrCustomer: {}, // 客户信息
				showWorkBox: false, // 管理客户作品
				showWorkForm: false, // 添加作品弹窗
				isAdmin:false,
				arrWorkForm:{// 添加作品的表单
					type:'1',
				}, 
				worksList: [], // 作品列表
				worksAudit:[], // 已审核作品数量
				showVerify:false,	//审核弹窗
				addFormData: {},
				work_file_path:[],
				sUrl:'/crm.works/uploadFile',
				auditWorkId:0,
				content:'',
				//验证器
				AddRules: {
					name: [{
							required: true,
							message: "请输入活动名称",
							trigger: "blur"
						},
						{
							min: 1,
							max: 30,
							message: "长度在 1 到 30 个字符",
							trigger: "blur"
						}, //trigger: 'blur' 鼠标失去焦点时触发
					],
					type: [{
							required: true,
							message: "请选择活动类型",
							trigger: "blur"
						},

					],
				},
				dialogVisible: false,
				bindFriend:false, // 绑定好友
				arrWechatFrinend:[], // 微信好友列表
				arrImage:{}, // 图片作品
				ADMIN_API_URL, // 管理后台地址
				showBigWork:false, // 展示作品大图
				arrWorkFile : [], // 图片作品列表
				dialogImageUrl:'', // 大图
			};
		},
		created() {
			//获取表单数据
			this.getInfo();
			this.getIsAdmin();
			this.getWechatUserList();
		},
		computed:{
			uploadHeader() {
				let header = {
					token: "",
					tokenkey: "",
				};
				header.token = window.localStorage.getItem("token");
				header.tokenkey = window.localStorage.getItem("tokenkey");
				return header;
			},
		},
		methods: {
			/**
			 * 编辑客户作品
			 */
			editWork(row){
				this.showWorkForm = true;
				this.arrWorkForm = row
				console.log(row)
			},
			/**
			 * 检测当前用户是否为超管
			 */
			getIsAdmin(){
				oIndex.getIsAdmin().then((res) => {
					this.isAdmin = res
				});
			},
			/**
			 * 添加作品
			 */
			addWork(){
				let data = this.arrWorkForm;
				data.customer_id = this.itemData.id;
				oIndex.addWork(data).then((res) => {
					this.showWorkForm = false;
					this.arrWorkForm = {
						type:1,
					}, 
					this.getInfo();
				});
			},
			/**
			 * 添加沟通记录
			 */
			addRecord() {
				let data = {
					id: this.itemData.id,
					contact: this.mark,
				};
				oIndex.addRecord(data).then((res) => {
					this.mark = '';
					this.getInfo();
				});
			},
			/**
			 * 客户确认意向
			 */
			confirmSuccess() {
				let data = {
					id: this.itemData.id,
					remark: this.confirmMark,
				};
				oIndex.confirmSuccess(data).then((res) => {
					this.showConfirmBox = false
					this.getInfo();
				});
			},
			/**
			 * 获取步骤条的样式
			 * @param {Object} key 当前步骤
			 */
			getStepStatus(key) {
				if (!this.nFailure) {
					return '';
				}
				if (key > this.arrCustomer.state) {
					return 'error';
				}
			},
			/**
			 * 修改当前进度
			 * @param {Object} param
			 */
			changeState(param) {
				console.log(param)
			},
			/**
			 * 关闭抽屉
			 */
			closeDrawer() {
				this.$emit('closeDrawer');
			},
			//添加按钮
			addSubmit() {
				this.$refs.AddRuleForm.validate((validate) => {
					if (validate) {
						this.addApi();
					} else {
						return false;
					}
				});
			},
			//添加接口
			editUser() {
				let data = this.arrCustomer;
				data.id = this.itemData.id
				oIndex.editUser(data).then((res) => {
					this.dialogVisible = false;
					this.getInfo();
				});
			},
			/**
			 * 获取信息
			 */
			getInfo() {
				oIndex.getEditInfo({
					id: this.itemData.id
				}).then((res) => {
					this.arrCustomer = res.info
					if (res.info.wechat_friend == 0) {
						this.arrCustomer.wechat_friend = '';
					}
					
					this.activities = res.log
					this.worksList = res.work
					this.worksAudit = res.work_audit
					console.log(res.info.result)
					if (res.info.result == 9) {
						this.nFailure = true;
					}
					if (res.info.state > 3) {
						this.nSuccess = true;
					}
				});
			},
			/**
			 * 微信好友列表
			 */
			getWechatUserList(){
				let data = {};
				oJs.request('admin/xbot.friend/getFriendList', data).then(res => {
					this.arrWechatFrinend = res;
				})
			},
			/**
			 * 沟通失败
			 * @param {Object} value
			 */
			contantFailure() {
				if (this.failMark.length <= 0) {
					this.$message.error('请输入备注信息')
				}
				let data = {
					id: this.itemData.id,
					remark: this.failMark
				}
				oIndex.contantFailure(data).then((res) => {
					this.failMark = '';
					this.nFailure = true
					this.showFaileBox = false
					this.getInfo()
				});
			},
			/**
			 * 关闭沟通失败弹窗
			 */
			closeFail() {
				this.nFailure = false;
				this.showFaileBox = false;
			},
			edit() {
				this.dialogVisible = true;
			},
			/**
			 * 上传
			 * @param {Object} arr
			 */
			uploadChange(arr){
			    this.work_file_path = arr['arr']
			},
			/**
			 * 删除作品
			 */
			deleteWork(data){
				let param = {
					id:data.id
				}
				oJs.request('admin/crm.customer/deleteWork', param).then(res => {
					this.getInfo();
				})
			},
			// 图片预览
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.response.data.show_url;
				this.showBigWork = true;
			},
			// 图片上传成功回调
			handleAvatarSuccess(response, file, fileList, item){
				
				if (response.code != 0){
					this.$message.error('图片上传失败');
				}else{
					this.arrWorkFile.push(response.data.sub_url)
				}
			},
			// 移除图片
			handleRemove(file, fileList){
				console.log(file, fileList);
			},
			// 添加文件作品
			addFileWork(){
				if (this.arrWorkFile.length <= 0) {
					this.$message.error('请上传作品');
				}else{
					let data = {
						file : this.arrWorkFile,
						customer_id : this.itemData.id
					}
					oJs.request('admin/crm.customer/addFileWork', data).then(res => {
						this.showWorkForm = false;
						this.getInfo();
					})
				}
				console.log(this.arrWorkFile);
			}
			
		},
	};
</script>

<style>
	.card-title {
		color: #00000;
		font-size: 14px;
		font-weight: 700;
	}

	.title-p {
		color: #999;
		font-size: 14px;
		line-height: 22px;
	}

	.div-span {
		color: #999;
		font-size: 12px;
		line-height: 20px;
	}

	.card-info {
		color: #333;
		font-size: 14px;
		line-height: 22px;
		margin-top: 2px;

	}

	.box-card-log {
		min-height: 98vh;
	}

	.close {
		width: 32px;
		height: 32px;
		background-color: #338aff;
		position: relative;
		left: -30px;
		top: 5px;
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
		text-align: center;
		cursor:pointer;
	}
	.tag {
		position: relative;
		top: -1px;
		display: inline-block;
		width: 6px;
		height: 6px;
		vertical-align: middle;
		border-radius: 50%;
		margin-right: 6px;
	}
	.el-upload-dragger{
		width: 148px;
		height: 148px;
	}
</style>

import oRequest from '@/libs/request.js';
const URL = {
	initPage: 'admin/crm.customer/initPage', //获取管理员列表页面构造
	getList: 'admin/crm.customer/getList', 
	allotCustomer: 'admin/crm.customer/allotCustomer', 
	getUserList: 'admin/sysUser/getUserList',
	contactCustomer: 'admin/crm.customer/contact', // 开始沟通客户
	getActivityList: 'admin/crm.activity/getActivityList', // 开始沟通客户
	contantFailure: 'admin/crm.customer/failure', // 沟通失败
	confirmSuccess: 'admin/crm.customer/confirm', // 确定意向
	addRecord: 'admin/crm.customer/addRecord', // 添加沟通记录
	addWork: 'admin/crm.customer/addWork', // 添加客户作品
	toAuditWork: 'admin/crm.customer/toAuditWork', // 添加客户作品
	getIsAdmin: 'admin/sysRole/getIsAdmin', // 获取是否为超管
	
	auditWork:"admin/crm.customer/auditWork",
	addUser: 'admin/crm.customer/addUser', //获取管理员用户天机
	editUser: 'admin/crm.customer/editInfo', //获取管理员用户天机
	getEditInfo: 'admin/crm.customer/getInfo', //获取管理员用户天机
	
	sendSms : "admin/crm.customer/sendSms", // 发送短信
	getSmsTemplate:"admin/sms.template/getTemplateList", // 获取短信模板
	
	
	
	
}
export default {
	getSmsTemplate(data = {}) {
		return new Promise((resolve, recject) => {
			oRequest.getAjax(URL.getSmsTemplate, data).then(res => {
				resolve(res);
			})
		})
	},
	sendSms(data = {}) {
		return new Promise((resolve, recject) => {
			oRequest.getAjax(URL.sendSms, data).then(res => {
				resolve(res);
			})
		})
	},
	getIsAdmin(data = {}) {
		return new Promise((resolve, recject) => {
			oRequest.getAjax(URL.getIsAdmin, data).then(res => {
				resolve(res);
			})
		})
	},
	toAuditWork(data = {}) {
		return new Promise((resolve, recject) => {
			oRequest.getAjax(URL.toAuditWork, data).then(res => {
				resolve(res);
			})
		})
	},
	auditWork(data = {}) {
		return new Promise((resolve, recject) => {
			oRequest.getAjax(URL.auditWork, data).then(res => {
				resolve(res);
			})
		})
	},
	addWork(data = {}) {
		return new Promise((resolve, recject) => {
			oRequest.getAjax(URL.addWork, data).then(res => {
				resolve(res);
			})
		})
	},
	addRecord(data = {}) {
		return new Promise((resolve, recject) => {
			oRequest.getAjax(URL.addRecord, data).then(res => {
				resolve(res);
			})
		})
	},
	confirmSuccess(data = {}) {
		return new Promise((resolve, recject) => {
			oRequest.getAjax(URL.confirmSuccess, data).then(res => {
				resolve(res);
			})
		})
	},
	contantFailure(data = {}) {
		return new Promise((resolve, recject) => {
			oRequest.getAjax(URL.contantFailure, data).then(res => {
				resolve(res);
			})
		})
	},
	getActivityList(data = {}) {
		return new Promise((resolve, recject) => {
			oRequest.getAjax(URL.getActivityList, data).then(res => {
				resolve(res);
			})
		})
	},
	contactCustomer(data = {}) {
		return new Promise((resolve, recject) => {
			oRequest.getAjax(URL.contactCustomer, data).then(res => {
				resolve(res);
			})
		})
	},
	allotCustomer(data = {}) {
		return new Promise((resolve, recject) => {
			oRequest.getAjax(URL.allotCustomer, data).then(res => {
				resolve(res);
			})
		})
	},
	getUserList(data = {}) {
		return new Promise((resolve, recject) => {
			oRequest.getAjax(URL.getUserList, data).then(res => {
				resolve(res);
			})
		})
	},
	discard(data = {}) {
		return new Promise((resolve, recject) => {
			oRequest.getAjax(URL.discard, data).then(res => {
				resolve(res);
			})
		})
	},
	cluesToCustomer(data = {}) {
		return new Promise((resolve, recject) => {
			oRequest.getAjax(URL.cluesToCustomer, data).then(res => {
				resolve(res);
			})
		})
	},
	editUser(data = {}) {
		return new Promise((resolve, recject) => {
			oRequest.getAjax(URL.editUser, data).then(res => {
				resolve(res);
			})
		})
	},
	getEditInfo(data = {}) {
		return new Promise((resolve, recject) => {
			oRequest.getAjax(URL.getEditInfo, data).then(res => {
				resolve(res);
			})
		})
	},
	initPage(data = {}) {
		return new Promise((resolve, recject) => {
			oRequest.getAjax(URL.initPage, data).then(res => {
				resolve(res);
			})
		})
	},
	getList(data = {}) {
		return new Promise((resolve, recject) => {
			oRequest.getAjax(URL.getList, data).then(res => {
				resolve(res);
			})
		})
	},
	addUser(data = {}) {
		return new Promise((resolve, recject) => {
			oRequest.getAjax(URL.addUser, data).then(res => {
				resolve(res);
			})
		})
	},
	getRoleList(data = {}) {
		return new Promise((resolve, recject) => {
			oRequest.getAjax(URL.getRoleList, data).then(res => {
				resolve(res);
			})
		})
	},

}

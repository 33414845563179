<template>
    <div class="display">
        <div :class="['uploadImg',item.animation?'tromfrom':'uptromfrom']" v-for="(item,index) in arrList" :key="index">
            <el-image style="width: 100%; height: 100%" :preview-src-list="[item.show_url]" :src="item.show_url" :fit="fit"></el-image>
            <i class="el-icon-circle-close" @click="del(arrData,index)"></i>
        </div>
        <div class="el-upload el-upload--picture-card" @click="upload('uploadinput')">
            <i class="el-icon-plus"></i>
            <input type="file" multiple class="el-upload__input" ref="uploadinput" @change="uploadChange($event,arrData,nNum)">
        </div>
    </div>
</template>

<script>
	import config from "../../config.js";
    export default {
        props: {
            arrData:{
                type:Array,
                default:[]
            },
            name:{
                type:String,
                default:''
            },
            sUrl:{
                type:String,
                default:''
            },
            nNum:{
                type:String,
                default:10
            },
        },
        data() {
            return {
                fit:'cover',
                
            }
        },
        computed:{
            arrList(){
                this.arrData.forEach(it=>{
                    it.animation = true;
                })
                return this.arrData
            }
        },
        methods: {
            del(arr,index){
                arr[index].animation = false
                setTimeout(()=>{
                    arr.splice(index,1);
                },500)
            },
            upload(refsType){
                this.sRefType = refsType;
                this.$refs[refsType].dispatchEvent(new MouseEvent("click"));
            },
            uploadChange(params,arr,num = 10){
				for (var i=0; i < params.target.files.length; i++) {
					let file = params.target.files[i];
					let spliceFile = file.type.split('/')
					if(spliceFile[1] != 'png' && spliceFile[1] != 'jpg' && spliceFile[1] != 'jpeg' && spliceFile[1] != 'gif'){
					    this.$message.error('上传格式不正确');
					    return 
					}
					//数量限制
					if(arr.length == num) {
					    this.$message.error(`不可以超过${num}张`);
					    return
					}
					let formData = new FormData()
					formData.append('file', file)
					let token = window.localStorage.getItem('token');
					let tokenkey = window.localStorage.getItem('tokenkey');
					this.$axios({
						// url: `http://vote.dev.3306c.cn/${this.sUrl}`,
					    url: `${config.host}/${this.sUrl}`,
						method: 'post',
						data: formData,
						headers: {
							'Content-Type': 'multipart/form-data',
							token,
							tokenkey
						}
					}).then(res => {
						if (res.data.code == 0) {
					        res.data.data.animation = true;
					        arr.push(res.data.data);
					        this.$refs[this.sRefType].value = null;
							this.$message.success('导入成功');
					        this.$emit('uploadChange',{arr:arr,name:this.name})
						} else {
							this.$message.warning('导入失败')
					        this.$refs[this.sRefType].value = null;
						}
					})
				}
            },
        },
    }
</script>

<style scoped>
.el-upload{
    cursor: pointer;
}
.uploadImg{
    border-radius: 6px;
    box-sizing: border-box;
    width: 148px;
    height: 148px;
    vertical-align: top;
    margin-right: 20px;
    margin-bottom: 20px;
    opacity: 0;
    position: relative;
}
.tromfrom{
    animation: tromfrom 0.8s forwards;
}
.uptromfrom{
    animation: uptromfrom 0.8s forwards;
}
/deep/ .uploadImg img{
    border-radius: 6px;
}
.uploadImg i{
    font-size: 20px;
    color: red;
    position: absolute;
    top: -10px;
    right: -10px;
}
.display{
    display: flex;
    flex-wrap: wrap;
    width: 60%;
}
@keyframes tromfrom{
    0%{
        opacity: 0;
        transform: translateY(-30px);
    }
    100%{
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes uptromfrom{
    0%{
        opacity: 1;
        transform: translateY(0);
    }
    100%{
        opacity: 0;
        transform: translateY(-30px);
    }
}
</style>